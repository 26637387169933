<template>
	<section class="container section-about section" id="about">
		<p class="section__text section-about__text">
			{{ $t('lnd.s2.t1') }}
		</p>
		<div class="section-about__map" style="position: relative;">
			<img src="@/assets/images/landing-map.svg" class="section-about__map-image" alt="map">
			<div style="position: absolute; left: 0; top: 0; width: 100%; height: 100%;">
				<canvas ref="canvas" style="width: 100%; height: 100%;"></canvas>
			</div>
		</div>
	</section>
</template>
<script>
import * as rive from "@rive-app/canvas";

export default {
	name: 'LndAbout',
	mounted() {
		setTimeout(() => {
			const r = new rive.Rive({
				// src: "https://cdn.rive.app/animations/vehicles.riv",
				src: "map.riv",
				// OR the path to a discoverable and public Rive asset
				// src: '/public/example.riv',
				canvas: this.$refs.canvas,
				autoplay: true,
				onLoad: () => {
					r.resizeDrawingSurfaceToCanvas();
				},
			});
		}, 500)
	}
}
</script>